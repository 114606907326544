import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import { useMd } from "../hooks/useMd";
import { Shard } from "./Shard";

const components = { Shard, Link };

const ArticleContent = styled.div`
  max-width: 900px;
  /* width: 95%; */
  margin: 25px auto;

  @media (max-width: 1050px) {
    width: 95vw;
  }
`;

const Page = () => {
  const location = useLocation();
  const slug = location.pathname.slice(1);
  const [MdxContent, setMdxContent] = useState<React.JSX.Element | null>(null);
  const file = useMd(slug);

  useEffect(() => {
    const hash = location.hash;
    let observer: MutationObserver | null = null;
    if (hash) {
      const element = document.getElementById(hash.slice(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      } else {
        observer = new MutationObserver(() => {
          const element = document.getElementById(hash.slice(1));
          if (element) {
            element.scrollIntoView();
            observer?.disconnect();
          }
        });
        observer.observe(document, { subtree: true, childList: true });
      }
    } else {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }, 10);
    }
    return () => {
      observer?.disconnect();
    };
  }, [location]);

  useEffect(() => {
    if (file) {
      import(`../pages/${file.path}`).then((module) => {
        setMdxContent(React.createElement(module.default, { components }));
      });
    }
  }, [file]);

  return <ArticleContent>{MdxContent}</ArticleContent>;
};

export default Page;
