import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { Route, Routes, useLocation } from "react-router-dom";
import Page from "./components/Page";
import { lightTheme, darkTheme, GlobalStyle } from "./theme";
import { Header, Navigation, DarkModeSwitch, Ash } from "./components";
import styled, { ThemeProvider } from "styled-components";
import heroImage from "./images/hero.webp";

const HeroBackground = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: center;
  max-width: 100vw;
  /* darken image */
  filter: brightness(0.5);
  padding: 0px;
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  color: white;
  font-family: "Nuernberg";
  letter-spacing: 0.15vw;
  font-size: 10vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 700px) {
    font-size: 20vw;
    letter-spacing: 1vw;
  }
  gap: 50px;
`;

const Emphasis = styled.span`
  color: ${(p) => p.theme.pink["fg"]};
`;

const HeroScroll = styled.a`
  letter-spacing: 0.1vw !important;
  font-size: 1.25rem !important;
  text-decoration: none !important;
  color: white !important;
  border: 1px solid white !important;
  font-family: "NKDuy" !important;
  font-weight: 100 !important;

  &:hover {
    color: black !important;
  }

  &:after {
    background-color: white !important;
  }
`;

const HeroText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (min-width: 1050px) {
    gap: 25px;
  }
`;

const NavPortal = styled.div`
  position: sticky;
  top: 150px;
`;

type AppContainerProps = {
  hasHero: boolean | null;
};
const AppContainer = styled.div<AppContainerProps>`
  background-color: ${(p) => p.theme.bg[0]};
  margin-top: ${(p) => (p.hasHero ? "100vh" : "0px")};
  display: ${(p) => (p.hasHero === null ? "none" : "block")};
`;

const App = () => {
  const location = useLocation();
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [isDomLoaded, setIsDomLoaded] = useState(false);
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  // Add listener to check when nav becomes mobile
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1050) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    setIsDomLoaded(true);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    console.log("mount");
    const isDarkStorage = localStorage.getItem("isDark");
    if (isDarkStorage && JSON.parse(isDarkStorage)) {
      setIsDarkMode(true);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("isDark", JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  const [hasHero, setHasHero] = useState(false);

  useEffect(() => {
    if (location.pathname === "/") {
      setHasHero(true);
    } else {
      setHasHero(false);
    }
  }, [location]);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      {hasHero && (
        <>
          <HeroBackground src={heroImage} />
          <Ash />
          <Hero>
            <HeroText>
              <div>Long ago,&nbsp;</div>
              <div>
                <Emphasis>titans</Emphasis>&nbsp;Walked
              </div>
              <span>&nbsp;the land.</span>
            </HeroText>
            <HeroScroll
              onClick={() => {
                window.scrollTo({
                  top: window.innerHeight,
                  behavior: "smooth",
                });
              }}
            >
              Enter a world of ruin.
            </HeroScroll>
          </Hero>
        </>
      )}
      <AppContainer hasHero={hasHero}>
        <Header>
          {isMobile ? (
            <Navigation title="" />
          ) : (
            <>
              {hasHero !== null &&
                isDomLoaded &&
                createPortal(
                  <Navigation title="" />,
                  document.getElementById("nav-portal")!
                )}
            </>
          )}
          {/* <SearchModal /> */}
          <DarkModeSwitch isDark={isDarkMode} onChange={toggleDarkMode} />
        </Header>
        <MainContent>
          <NavPortal>
            <div id="nav-portal" />
          </NavPortal>
          <GlobalStyle />
          <Routes>
            <Route path="/" element={<Page />} />
            <Route path="/:page" element={<Page />} />
          </Routes>
        </MainContent>
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
