import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    width: 100vw;
    overflow-x: hidden;
    min-height: 100vh;
    background: ${(p) => p.theme.bg[0]};
    font-family: 'NKDuy', serif;
    font-weight: 100;
    color: ${(p) => p.theme.fg[1]};
  }

  * {
    scroll-margin-top: 160px;
  }

  strong {
    color: ${(p) => p.theme.pink["fg"]};
  }

  * {
    box-sizing: border-box;
  }
  
  .link {
    all: unset;
    font-weight: 700;
    position: relative;
    cursor: pointer;
    color: ${(p) => p.theme.pink["fg"]};
    display: inline-block;
    transition: color 100ms ease-in-out;
    margin: 0 1px;
    &:after {
      z-index: -1;
      position: absolute;
      /* background-color: ${(p) => p.theme.pink["bg"]}; */
      content: '';
      top: 0;
      left: 0;
      width: 0%;
      height: 100%;
      transition: width 100ms ease-in-out;
      background-color: ${(p) => p.theme.pink["bg"]};
    }
    &:hover:after {
      width: 100%;
    }
    &:hover {
      color: ${(p) => p.theme.fg[0]};
    }

    /* underline */
    text-decoration: underline;
  }
  
  
  a.header-link {
    fill: ${(p) => p.theme.pink["fg"]};
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: 'Nuernberg', serif;
    color: ${(p) => p.theme.fg[0]};
    letter-spacing: 0.05rem;
  }
  h1 {
    font-size: 4em;
  }
  h2 {
    font-size: 3.5em;
    margin: 25px 0px;
  }
  h3 {
    font-size: 3em;
    margin: 15px 0px;
  }
  h4 {
    font-size: 2.25em;
    margin: 10px 0px;
  }
  h5 {
    font-size: 2em;
    margin: 5px 0px;
  }
  h6 {
    font-size: 1.5em;
    margin: 5px 0px;
  }

  // First header should always have a top margin of 0
  h1:first-child,
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0;
  }

  .caption {
    /* font-size: 0.9em; */
    color: ${(p) => p.theme.fg[2]};
    width: 80%;
    margin: auto;
    text-align: center;
  }

  table {
    padding: 10px;
    border-radius: 5px;
    border-spacing: 0;
    border-collapse: separate;
  }

  table th {
    background-color: ${(p) => p.theme.pink["bg"]};
    border: 1px solid ${(p) => p.theme.pink["bg"]};
    text-align: left;
    padding: 5px;
  }

  table td {
    padding: 5px;
    border: 1px solid ${(p) => p.theme.pink["bg"]};
    border-right: none;
    border-top: none;
  }

  /* select the last cell of each row */
  table tr td:last-child {
    border-right: 1px solid ${(p) => p.theme.pink["bg"]};
  }

  /* select the first cell of the last row */
  table tr:last-child td:first-child {
    border-bottom-left-radius: 5px;
  }

  /* select the last cell of the last row */
  table tr:last-child td:last-child {
    border-bottom-right-radius: 5px;
  }

  /* select the first cell of the header row */
  table tr:first-child th:first-child {
    border-top-left-radius: 5px;
  }

  /* select the last cell of the header row */
  table tr:first-child th:last-child {
    border-top-right-radius: 5px;
  }

`;

export default GlobalStyle;
