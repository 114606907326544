import React from "react";
import { useState } from "react";
import styled from "styled-components";

// Essence shard exchange rates:

// | Shard Size | skor (ᚪ) | dram (ᚧ) | keld (ᚳ) | orin (ᛟ) |
// | ---------- | -------- | -------- | -------- | -------- |
// | skor (ᚪ)   | 1        | 1/10     | 1/100    | 1/10000  |
// | dram (ᚧ)   | 10       | 1        | 1/10     | 1/1000   |
// | keld (ᚳ)   | 100      | 10       | 1        | 1/100    |
// | orin (ᛟ)   | 10000    | 1000     | 100      | 1        |

// Component for displaying a certain amount of currency in a given denomination, with a tooltip showing conversions of the amount to other denominations.

// Props:
// - amount: number
// - denomination: string

const StyledCurrency = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: relative;
  cursor: pointer;
`;

const StyledCurrencySymbol = styled.span`
  transform: translate(0, -3px);
`;

const StyledCurrencyTooltip = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: baseline;
  border-radius: 5px;
  padding: 2px 4px;
  margin: 2px 4px;
  top: 100%;
  background-color: ${(p) => p.theme.bg[0]};
  border: 1px solid ${(p) => p.theme.pink["fg"]};
  z-index: 1000;
  box-shadow: 0px 0px 5px 0px black;
`;

type CurrencyProps = {
  a: number;
  d: string;
};

export const Shard = ({ a, d }: CurrencyProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const getDenominationSymbol = (denomination: string) => {
    const denominationSymbols = {
      skor: "ᚪ",
      dram: "ᚧ",
      keld: "ᚳ",
      orin: "ᛟ",
    } as any;
    return denominationSymbols[denomination];
  };

  const getTooltipComponent = (amount: number, denomination: string) => {
    // We only need to show the conversions to other denominations that are smaller than the given denomination (keeping in mind we can only have whole values of currency).
    // So, if the given denomination is "keld", we only need to show the conversions to "dram" and "skor" broken down into whole values of those denominations
    // i.e. if the value is 3 Keld, we should return the string: 3 Keld, 30 Dram and 150 Skor.

    // We can use the following table to determine which denominations are smaller than the given denomination:
    // | Shard Size | skor (ᚪ) | dram (ᚧ) | keld (ᚳ) | orin (ᛟ) |
    // | ---------- | -------- | -------- | -------- | -------- |
    // | skor (ᚪ)   | 1        | 1/10     | 1/100    | 1/10000  |
    // | dram (ᚧ)   | 10       | 1        | 1/10     | 1/1000   |
    // | keld (ᚳ)   | 100      | 10       | 1        | 1/100    |
    // | orin (ᛟ)   | 10000    | 1000     | 100      | 1        |

    const getConversionRate = (
      denomination1: string,
      denomination2: string
    ) => {
      const conversionRates = {
        skor: {
          skor: 1,
          dram: 10,
          keld: 1000,
          orin: 10000,
        },
        dram: {
          skor: 1 / 10,
          dram: 1,
          keld: 10,
          orin: 1000,
        },
        keld: {
          skor: 1 / 100,
          dram: 1 / 10,
          keld: 1,
          orin: 100,
        },
        orin: {
          skor: 1 / 10000,
          dram: 1 / 1000,
          keld: 1 / 100,
          orin: 1,
        },
      } as any;
      return conversionRates[denomination1][denomination2];
    };

    const denominations = ["skor", "dram", "keld", "orin"];
    const denominationsToDisplay = denominations.slice(
      0,
      denominations.indexOf(denomination) + 1
    );
    denominationsToDisplay.reverse();
    console.log(denominationsToDisplay);
    const denominationsToDisplayWithAmounts = denominationsToDisplay.map(
      (denominationToDisplay) => {
        const amountToDisplay = Math.floor(
          amount * (1 / getConversionRate(denomination, denominationToDisplay))
        );
        return {
          amount: amountToDisplay,
          denomination: denominationToDisplay,
          symbol: getDenominationSymbol(denominationToDisplay),
        };
      }
    );
    console.log(denominationsToDisplayWithAmounts);
    return (
      <StyledCurrencyTooltip>
        {denominationsToDisplayWithAmounts.map(
          (denominationToDisplayWithAmount) => {
            return (
              <StyledCurrency>
                <span>{denominationToDisplayWithAmount.amount}</span>
                <span>{denominationToDisplayWithAmount.denomination}</span>
                <StyledCurrencySymbol>
                  {denominationToDisplayWithAmount.symbol}
                </StyledCurrencySymbol>
              </StyledCurrency>
            );
          }
        )}
      </StyledCurrencyTooltip>
    );
  };

  return (
    <StyledCurrency
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <span>{a}</span>
      <StyledCurrencySymbol>
        {getDenominationSymbol(d)}
      </StyledCurrencySymbol>
      {showTooltip && getTooltipComponent(a, d)}
    </StyledCurrency>
  );
};
