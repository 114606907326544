import _ from "lodash";
import React, { useEffect } from "react";
import styled from "styled-components";

const StyledCanvas = styled.canvas`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -2;
`;

export const Ash = () => {
  useEffect(() => {
    const animFrame = window.requestAnimationFrame;
    const Snow = (canvas: any, count: any, options: any) => {
      const ctx = canvas.getContext("2d");
      const snowflakes = [] as any;

      const add = (item: any) => snowflakes.push(item(canvas));

      const update = () => _.forEach(snowflakes, (el) => el.update());

      const resize = () => {
        ctx.canvas.width = canvas.offsetWidth;
        ctx.canvas.height = canvas.offsetHeight;

        _.forEach(snowflakes, (el) => el.resized());
      };

      const draw = () => {
        ctx.clearRect(0, 0, canvas.offsetWidth, canvas.offsetHeight);
        _.forEach(snowflakes, (el) => el.draw());
      };

      const events = () => {
        window.addEventListener("resize", resize);
      };

      const loop = () => {
        draw();
        update();
        animFrame(loop);
      };

      const init = (count: any) => {
        _.times(count, () => add((canvas: any) => SnowItem(canvas, null, options)));
        events();
        loop();
      };

      init(count);
      resize();

      return { add, resize };
    };

    const defaultOptions = {
      color: "orange",
      radius: [0.5, 3.0],
      speed: [1, 3],
      wind: [-0.5, 3.0],
    };

    const SnowItem = (canvas: any, drawFn: any = null, opts: any) => {
      const options = { ...defaultOptions, ...opts };
      const { radius, speed, wind, color } = options;
      const params = {
        color,
        x: _.random(0, canvas.offsetWidth),
        y: _.random(-canvas.offsetHeight, 0),
        height: _.random(...radius),
        width: _.random(...radius),
        speed: _.random(...speed),
        wind: _.random(...wind),
        isResized: false,
        opacity: Math.random() * (1 - 0.3) + 0.3,
      };
      const ctx = canvas.getContext("2d");

      const updateData = () => {
        params.x = _.random(0, canvas.offsetWidth);
        params.y = _.random(-canvas.offsetHeight, 0);
      };

      const resized = () => (params.isResized = true);

      const drawDefault = () => {
        ctx.beginPath();
        const ellipseWidth = params.width * (_.random(80, 120) / 100); // Random width between 80% to 120% of radius
        const ellipseHeight = params.height * (_.random(80, 120) / 100); // Random height between 80% to 120% of radius
        ctx.ellipse(
          params.x,
          params.y,
          ellipseWidth,
          ellipseHeight,
          0,
          0,
          2 * Math.PI
        );
        const flickerOpacity = Math.max(
          0,
          Math.min(1, params.opacity + (Math.random() - 0.5) * 0.2)
        ); // Adjust opacity by up to 0.1
        ctx.fillStyle = `rgba(200, 200, 200, ${flickerOpacity})`;
        ctx.fill();
        ctx.closePath();
      };

      const draw = drawFn ? () => drawFn(ctx, params) : () => drawDefault();

      const translate = () => {
        params.y += params.speed;
        params.x += params.wind;
      };

      const onDown = () => {
        if (params.y < canvas.offsetHeight) return;

        if (params.isResized) {
          updateData();
          params.isResized = false;
        } else {
          params.y = 0;
          params.x = _.random(0, canvas.offsetWidth);
        }
      };

      const update = () => {
        translate();
        onDown();
      };

      return {
        update,
        resized,
        draw,
      };
    };

    const canvas = document.getElementById("snow");
    Snow(canvas, 300, { color: "white" });
  }, []);

  return <StyledCanvas id="snow" />;
};
