import { useEffect, useState } from "react";
import { Link } from "react-router-dom";  
import styled from "styled-components";
import Hamburger from "hamburger-react";
import { useAllMd } from "../hooks/useMd";

type NavProps = {
  open: boolean;
};

const NavContainer = styled.div<NavProps>`
  top: 150px;
  left: 0;
  /* Collapse navigation on mobile */
  @media (max-width: 1050px) {
    position: fixed;
    /* opacity: ${(p) => (p.open ? 1 : 0)}; */
    width: ${(p) => (p.open ? "300px" : "0px")};
    left: 0;
    top: 150px;
    background-color: ${(p) => p.theme.bg[0]};
    transition: width 0.2s ease-in-out;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    z-index: 1000;
  }

  a {
    padding: 2px 4px;
    text-decoration: none;
  }
`;

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px;
  max-width: 300px;
  width: 300px;
  height: calc(100vh - 150px);
  position: sticky;
  padding-bottom: 100px;
`;

const NavHeader = styled.h2`
  margin-bottom: 0;
  padding-bottom: 0;
  font-family: "Lazar", sans-serif;
  font-size: 5rem;
`;

type HeadingProps = {
  depth: number;
};

const HeadingLinkContainer = styled.div<HeadingProps>`
  padding-left: ${(p: HeadingProps) => p.depth * 10}px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const HeadingLink = styled(Link)`
    color: white;
    text-decoration: none;
    padding: 2px 4px;
    &:hover {
      color: ${(p) => p.theme.pink["fg"]};
      background-color: ${(p) => p.theme.bg[0]};
    }
`

const StyledHamburger = styled.div`
  position: absolute;
  top: 75px;
  transform: translateY(-50%);
  left: 20px;
  z-index: 1500;
  display: none;
  @media (max-width: 1050px) {
    display: block;
  }
`;

const ArticleTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
`;

type ArticleHeadersContainerProps = {
  open: boolean;
};
const ArticleHeadersContainer = styled.div<ArticleHeadersContainerProps>`
  /* animate the container up and down */
  background-color: ${(p) => p.theme.pink["bg"]};
  /* max-height: ${(p: ArticleHeadersContainerProps) =>
    p.open ? "1000px" : "0px"};
  transition: max-height 0.2s ease-in-out; */
  display: ${(p: ArticleHeadersContainerProps) => (p.open ? "block" : "none")};
  padding: ${(p) => (p.open ? "10px" : "0px")};
  margin: ${(p) => (p.open ? "10px" : "0px")};
  border-radius: 5px;

  overflow-y: scroll;
  width: 100%;
`;

type ListExpandIconProps = {
  open: boolean;
};
const ListExpandIcon = styled.div<ListExpandIconProps>`
  &::before {
    content: "▼";
  }
  transform: ${(p) => (p.open ? "rotate(0deg)" : "rotate(-90deg)")};
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
`;

type NavigationProps = {
  title: string;
};

export const Navigation = ({ title }: NavigationProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [activeArticle, setActiveArticle] = useState<string | null>(null);
  const data = useAllMd();

  useEffect(() => {
    if (title) {
      setActiveArticle(title);
    }
  }, [title]);

  return (
    <>
      <StyledHamburger>
        <Hamburger toggled={open} toggle={setOpen} size={20} />
      </StyledHamburger>
      <NavContainer open={open}>
        <Nav>
          <NavHeader>Navigation</NavHeader>
          {Object.keys(data).length > 0 &&
            Object.entries(data).map(([slug, node]) => {
              return (
                <>
                  <ArticleTitle>
                    {node.headers && node.headers.length > 0 && (
                      <ListExpandIcon
                        open={activeArticle === slug}
                        onClick={() => {
                          if (activeArticle === slug) {
                            setActiveArticle(null);
                          } else {
                            setActiveArticle(slug);
                          }
                        }}
                      />
                    )}
                    <Link
                      className="link"
                      key={slug}
                      to={`/${slug}`}
                      onClick={() => {
                        setActiveArticle(slug);
                      }}
                    >
                      {node.title}
                    </Link>
                  </ArticleTitle>
                  <ArticleHeadersContainer open={activeArticle === slug}>
                    {node.headers && node.headers.map((header) => {
                      return (
                        <HeadingLinkContainer depth={header.depth}>
                          •{" "}
                          <HeadingLink
                            to={`/${slug}#${header.id}`}
                            onClick={() => {
                              setActiveArticle(slug);
                            }}
                          >
                            {header.title}
                          </HeadingLink>
                        </HeadingLinkContainer>
                      );
                    })}
                  </ArticleHeadersContainer>
                </>
              );
            })}
        </Nav>
      </NavContainer>
    </>
  );
};
