import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  top: 0;
  background-color: ${(p) => p.theme.bg[0]};
  height: 150px;
  z-index: 1000;
  position: sticky;
`;

const Title = styled(Link)`
  all: unset;
  font-family: "Lazar";
  font-size: 6em;
  color: ${(p) => p.theme.fg[0]};
  word-spacing: 10px;
  cursor: pointer;
  word-spacing: 0px;

  @media (max-width: 450px) {
    font-size: 4em;
  }
`;

const Color = styled.span`
  color: ${(p) => p.theme.pink["fg"]};
`;

// pass children in props
type HeaderProps = {
  children: React.ReactNode;
};

const Header = ({ children }: HeaderProps) => {
  return (
    <StyledHeader>
      <Title
        to="/"
      >
        WHEN <Color>TITANS</Color> FELL
      </Title>
      {children}
    </StyledHeader>
  );
};

export default Header;
