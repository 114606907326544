import { DefaultTheme } from "styled-components";

const darkTheme: DefaultTheme = {
  bg: ["#000000", "#222124", "#39383c"],
  fg: ["#ffffff", "rgba(255,255,255,0.7)", "rgba(255,255,255,0.5)"],
  pink: { fg: "#EA0E6C", bg: "#EA0E6C" },
};

const lightTheme: DefaultTheme = {
  fg: ["#1b1a1c", "#222124", "#39383c"],
  bg: ["#ffffff", "#dbdadd", "#c4c3c6"],
  pink: { fg: "#EA0E6C", bg: "#EA0E6C" },
};

export { darkTheme, lightTheme };
