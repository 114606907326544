import { useEffect, useState } from "react";
import md from "../pages.json"
import { MdData, MdMetadata } from "../types";

const globalData = md as MdData;

export const useAllMd = () => {
    console.log(globalData);
    return globalData;
}

export const useMd = (slug: string) => {
    const [data, setData] = useState<MdMetadata | null>(null);
    useEffect(() => {
        const metadata = globalData[slug];
        if (metadata) {
            setData(metadata);
        }
    }, [slug]);
    return data;
};
